// support both padded and non-padded routes (padded is canonical)
const BASE_REGEX = /^\/(?<year>\d{4})\/(?<month>[01]\d)\/(?<day>\d{1,2})\/(?<slug>[^/]+)\/?$/i
const PAGE_REGEX = /^\/(?<year>\d{4})\/(?<month>[01]\d)\/(?<day>\d{1,2})\/(?<slug>[^/]+)\/(?<page>\d+)\/?$/i

export default pageContext => {
  let result = PAGE_REGEX.exec(pageContext.urlPathname)

  if (
    result
    && parseInt(result.groups.year, 10) >= 0
    && parseInt(result.groups.month, 10) >= 1
    && parseInt(result.groups.month, 10) <= 12
    && parseInt(result.groups.day, 10) >= 1
    && parseInt(result.groups.day, 10) <= 31
    && parseInt(result.groups.page, 10) >= 1
    && !!result.groups.slug
  ) {
    return {
      routeParams: result.groups,
      precedence: 10,
    }
  }

  result = BASE_REGEX.exec(pageContext.urlPathname)

  if (
    result
    && parseInt(result.groups.year, 10) >= 0
    && parseInt(result.groups.month, 10) >= 1
    && parseInt(result.groups.month, 10) <= 12
    && parseInt(result.groups.day, 10) >= 1
    && parseInt(result.groups.day, 10) <= 31
    && !!result.groups.slug
  ) {
    return {
      routeParams: result.groups,
      precedence: 10,
    }
  }

  return false
}
