const BASE_REGEX = /^\/(?<year>\d{4})\/?$/i
const PAGE_REGEX = /^\/(?<year>\d{4})\/page\/(?<page>\d+)\/?$/i

export default pageContext => {
  let result = PAGE_REGEX.exec(pageContext.urlPathname)

  if (
    result
    && parseInt(result.groups.year, 10) >= 0
    && parseInt(result.groups.page, 10) >= 1
  ) {
    return {
      routeParams: result.groups,
      precedence: 10,
    }
  }

  result = BASE_REGEX.exec(pageContext.urlPathname)

  if (
    result
    && parseInt(result.groups.year, 10) >= 0
  ) {
    return {
      routeParams: result.groups,
      precedence: 10,
    }
  }

  return false
}
