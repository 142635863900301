import { resolveRoute } from 'vike/routing'

export default pageContext => {
  let result = false

  result = resolveRoute('/tag/@slug', pageContext.urlPathname)
  if (result.match) {
    return result
  }

  result = resolveRoute('/tag/@slug/page/@page', pageContext.urlPathname)
  if (
    result.match
    && parseInt(result.routeParams.page, 10) >= 1
  ) {
    return result
  }

  return result
}
